import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FirebaseService {

    private collectionPath: string;

    constructor(private firestore: AngularFirestore) {
        // Determine the collection path based on the current URL
        const host = window.location.hostname;
        this.collectionPath = (host === 'zapapp-dev.coffeemonster.co.za' || host === 'localhost')
            ? 'zapper/merchants'
            : 'zapahead_live/merchants';
    }

    getOrders(merchant_id: string): Observable<any[]> {
        // Use the collection path determined in the constructor
        return this.firestore.collection(`${this.collectionPath}/${merchant_id}`).snapshotChanges();
    }

    updateOrderStatus(merchant_id: string, order_id: string, key: string, value: any) {
        let newData = { [key]: value };
        return this.firestore.doc(`${this.collectionPath}/${merchant_id}/${order_id}`).update(newData);
    }

    updateETA(merchant_id: string, order_id: string, key: string, value: any) {
        let newData = { [key]: value };
        return this.firestore.doc(`${this.collectionPath}/${merchant_id}/${order_id}`).update(newData);
    }

    deleteOrder(merchant_id: string, order_id: string) {
        return this.firestore.doc(`${this.collectionPath}/${merchant_id}/${order_id}`).delete();
    }
}
