import {Component, NgZone, OnInit} from '@angular/core';
import {AlertController, ModalController} from '@ionic/angular';
import {ReviewModalComponent} from "../review-modal/review-modal.component";
import {ApiService} from "../../../services/api.service";
import {CancelModalComponent} from "../cancel-modal/cancel-modal.component";
import {AppRate} from '@ionic-native/app-rate/ngx';
import { InAppBrowser, InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx';
import {encode} from 'punycode';
import { Router } from '@angular/router';
import { OrderStatuses } from '../../../common/order-statuses';
import { Device } from '@ionic-native/device/ngx';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import {Storage} from '@ionic/storage';
import {FirebaseService} from '../../../services/firebase.service';

import {Events} from '../../../services/events.service';

@Component({
    selector: 'app-order-modal',
    templateUrl: './order-modal.component.html',
    styleUrls: ['./order-modal.component.scss'],
})
export class OrderModalComponent implements OnInit {

    order: any;
    orderStatuses = OrderStatuses.ORDER_STATUSES;
    deliveryFee = 15;

		firebaseOrdersSubscriber;

    constructor(public modalController: ModalController,
                private events: Events,
                private zone: NgZone,
                private apiService: ApiService,
                private alert: AlertController,
                private appRate: AppRate,
                private iab: InAppBrowser,
                private router: Router,
								private firebaseService: FirebaseService,
                private device: Device,
                private appVersion: AppVersion,
                private storage: Storage) {
    }

    ngOnInit() {
      console.log(this.order);

			this.getOrdersFromFirebase();

      this.storage.get('deliveryFee').then((value) => {
        if(value) {
          this.deliveryFee = value;
        }
      });
    }


    async closeModal() {
        this.events.publish('closeOrderModal', {})
        await this.modalController.dismiss();
    }

    getOrdersFromFirebase() {
			this.firebaseOrdersSubscriber = this.firebaseService.getOrders(this.order.merchant.id).subscribe(data => {

					console.log('FIREBASE CHANGE DETECTED');

					data.forEach((e) => {

						if(this.order.id == e.payload.doc.id) {
							var data = e.payload.doc.data();

							// if(this.order.shortStatus != data['status']) {
							// 	this.order.shortStatus = data['status'];
							// }

              this.order.shortStatus = data['status'];

							console.log("Order status from Firebase is " + data['status']);
						}
					});

			});
		}


    imHere(order) {
        //Alert
        //Are you sure ?
        this.alert.create({
            header: 'I\'m Here',
            message: 'Ready for your order?',
            buttons: [
                {
                    text: 'OK',
                    handler: () => {
                        this.apiService.imHere(order).then((data: any) => {
                            this.events.publish('getOrders', {});
                            this.closeModal()
                        });
                    }
                }
                , {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: (a) => {
                    }
                }
            ]

        }).then((al) => {
            al.present();
        });
    }

    async rateMerchant(order) {
        const modal = await this.modalController.create({
            component: ReviewModalComponent,
            componentProps: {
                'order': order,
            }
        });

        modal.onDidDismiss()
          .then((orderId) => {
            this.router.navigate(['/u/orders'],  { queryParams: { orderId: orderId.data } });
          });

        return await modal.present();
    }

    async openCancel(order) {
        const modal = await this.modalController.create({
            component: CancelModalComponent,
            componentProps: {
                'order': order,
            }
        });
        return await modal.present();
    }

    range(min, max, step) {
        step = step || 1;
        var input = [];
        for (var i = min; i <= max; i += step) {
            input.push(i);
        }
        return input;
    }


    takeMeThere(order) {
        console.log(order);
        window.location.href = `https://www.google.com/maps/dir/?api=1&destination=${order.merchant.location.lat},${order.merchant.location.lng}`;
    }

    rateApp(order) {
        let preferences = {
            displayAppName: 'Coffee Monster',
            usesUntilPrompt: 0,
            promptAgainForEachNewVersion: false,
            inAppReview: true,
            data: {
                'api' :  this.apiService,
                'order': this.order,
                'alert' : this.alert,
                'events': this.events,
                'close' : this.closeModal()
            },
            storeAppURL: {
                ios: 'com.dawpro.moco',
                android: 'market://details?id=com.dawpro.moco'
            },
            customLocale: {
                title: "Would you mind rating %@?",
                message: "It won’t take more than a minute and helps to promote our app. Thanks for your support!",
                cancelButtonLabel: "No thanks",
                laterButtonLabel: "Remind me later",
                rateButtonLabel: "Rate it now",
                yesButtonLabel: "Yes!",
                noButtonLabel: "Not really",
                appRatePromptTitle: 'Do you like using %@',
                feedbackPromptTitle: 'Mind giving us some feedback?',
            },
            callbacks: {
                onRateDialogShow: function (callback) {
                    console.log('User Prompt for Rating');
                    console.log(callback);
                },
                onButtonClicked: function (buttonIndex) {
                    console.log('Selected Button Index', buttonIndex);
                    if (buttonIndex == 3) {
                        //They clicked the rating button
                        preferences.data.api.rateAppOnce({order_id: preferences.data.order.id}).then(function (response) {
                        })
                    }
                }
            },
            openUrl: (url: string) => {
                // return url;
                // console.log(url)
                window.location.href=url;
                // window.open(encodeURI(url), '_blank', 'location=yes')
                // this.iab.create(url, '_blank', 'location=yes');
            }

        }
        this.appRate.preferences = preferences;

        this.appRate.promptForRating(true);
    }

    showImHereButton(order) {
      let shortStatus = order.shortStatus ? order.shortStatus.toLowerCase() : null;

      return order.im_here == false && (shortStatus == 'merchant_viewed' || shortStatus == 'should_process'
              || shortStatus.toLowerCase() == 'in_progress' || shortStatus == 'ready_for_collection')
              && order.delivery_method !== 'Deliver to Me';
    }

    showCancelButton(order) {
      let shortStatus = order.shortStatus ? order.shortStatus.toLowerCase() : null;

      if(shortStatus == 'processing') {
        return true;
      }

    }

    openWhatsapp(order) {
      let version = null;
      let orderStatus = this.orderStatuses[order.shortStatus.toLowerCase()] ?
                        this.orderStatuses[order.shortStatus.toLowerCase()].longLabel
                        : order.shortStatus.split('_').join(' ');
      this.storage.get('user').then((user) => {
        // const userMail = user.profile.email;
        // version = versionNumber;
        const text = 'Hi%20I%20have%20a%20question%20relating%20to%20Order%20'
                      + order.id;

        // window.open('https://wa.me/+27768125904/?text=' + text, '_blank');

        // window.location.href = 'https://wa.me/27768125904?text=' + text;
        window.open('https://wa.me/27768125904?text=' + text, "_system")
      });
    }

}
